import { defineStore } from 'pinia'

// @ts-ignore
export const useUserPreferencesStore = defineStore('UserPreferences', {
  state: () => ({
    theme: 'light'
  }),
  getters: {
  },
  actions: {
  },
  persist: {
    key: 'cash-studio_user-preferences',
    cookieOptions: {
      maxAge: 365 * 24 * 60 * 60,
      // expires: 0
    },
    debug: true,
  },
})
