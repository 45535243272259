// This supports HMR
// import { defineAppConfig } from "nuxt/app"

export default defineAppConfig({
  title: 'Cash.Studio',
  ui: {
    primary: 'sky',
    notifications: {
      // Show toasts at the top right of the screen
      position: 'bottom-0 right-0'
    },
    navbar: {
      menu: [
      ],
      userMenu: [
        {
          title: 'Settings',
          to: '/account/settings'
        },
        {
          title: 'Sign Out',
          to: '/account/signout'
        }
      ],
      footerMenu: [
        {
          title: 'About',
          to: '/about'
        },
        {
          title: 'Contact',
          to: '/contact'
        }
      ]
    },
  }
})
