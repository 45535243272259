<template>
  <div
    class="pt-1"
  >
    <NavBar />
    <NuxtPage
      class="mt-[70px] mb-16 w-full h-screen"
    />
    <Footer />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

onMounted(() => {
  initFlowbite()
})

</script>
