<template>
  <footer
    class="fixed bottom-0 left-0 w-full bg-white p-4 border-t border-gray-200
      shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800
      dark:border-gray-600"
  >
    <span
      class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
    >
      © 2023 Cash Studio. All Rights
      Reserved.
    </span>
    <ul
      class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500
      dark:text-gray-400 sm:mt-0"
    >
      <li>
        <NuxtLink
          to="/about"
          class="mr-4 hover:underline no-underline focus:no-underline md:mr-6"
        >
          About
        </NuxtLink>
      </li>
    </ul>
  </footer>
</template>
