<template>
  <nav
    class="bg-white/30 dark:bg-gray-900/30 backdrop-blur-md fixed w-full
      z-20 top-0 left-0 right-0 border-b border-gray-200
      dark:border-gray-600"
  >
    <Html
      :class="colorTheme"
    />
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between
        mx-auto p-4"
    >
      <NuxtLink
        class="btn btn-ghost normal-case text-xl"
        to="/"
      >
        Cash.Studio {{ loggedIn }}
      </NuxtLink>
      <div
        class="flex md:order-2"
      >
        <button
          id="theme-toggle"
          type="button"
          class="mr-1 md:mr-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100
            dark:hover:bg-gray-700 focus:outline-none focus:ring-2
            focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm
            p-2.5"
          @click="toggleTheme"
        >
          <svg
            id="theme-toggle-dark-icon"
            ref="themeToggleDarkIcon"
            :class="{'hidden': theme !== 'light' }"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586
                10.586z"
            />
          </svg>
          <svg
            id="theme-toggle-light-icon"
            ref="themeToggleLightIcon"
            :class="{'hidden': theme !== 'auto' }"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0
                11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0
                001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1
                1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0
                011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011
                1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465
                5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414
                8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0
                011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
              fill-rule="evenodd"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            id="theme-toggle-auto-icon"
            ref="themeToggleAutoIcon"
            :class="{'hidden': theme !== 'dark' }"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12
                8V16Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12
              22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12
              4V8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12
              16V20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
            />
          </svg>
        </button>
        <div
          v-if="loggedIn"
          class="flex mx-0 md:order-3"
        >
          <button
            id="user-menu-button"
            type="button"
            class="text-sm p-0 m-0 bg-gray-800 rounded-full border-4
              border-gray-200 focus:ring-2 focus:ring-gray-300
              dark:focus:ring-gray-600"
            aria-expanded="false"
            data-dropdown-placement="bottom"
            data-dropdown-toggle="user-dropdown"
          >
            <span
              class="sr-only"
            >
              Open user menu
            </span>
            <img
              class="w-8 h-8 rounded-full"
              :src="profileImgSrc"
              alt="user photo"
            >
          </button>
        </div>
        <div
          id="user-dropdown"
          ref="userDropdownMenu"
          class="z-50 hidden my-4 text-base list-none bg-white divide-y
            divide-gray-100 rounded-lg shadow dark:bg-gray-700
            dark:divide-gray-600"
        >
          <div
            class="px-4 py-3"
          >
            <span
              class="block text-sm text-gray-900 dark:text-white"
            >
              {{ profileName }}
            </span>
            <span
              class="block text-sm  text-gray-500 truncate dark:text-gray-400"
            >
              {{ profileEmail }}
            </span>
          </div>
          <ul
            class="py-2"
            aria-labelledby="user-menu-button"
          >
            <li
              v-for="menuItem in userMenuItems"
              :key="menuItem.title"
            >
              <NuxtLink
                :to="menuItem.to"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                  dark:hover:bg-gray-600 dark:text-gray-200
                  dark:hover:text-white"
              >
                {{ menuItem.title }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <button
          id="navbar-menu-button"
          type="button"
          class="ml-1 inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100
            focus:outline-none focus:ring-2 focus:ring-gray-200
            dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          @click.stop="navbarDropdownVisible = !navbarDropdownVisible"
        >
          <span
            class="sr-only"
          >
            Open main menu
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 13"
            class="w-4 h-4"
            aria-hidden="true"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>

      <div
        id="navbar-menu"
        ref="navbarDropdownMenu"
        v-on-click-outside.bubble="navbarHandler"
        class="transition items-center justify-between w-full md:flex
          md:w-auto md:order-1 md:visible"
        :class="navbarDropdownVisible ? '' : 'hidden'"
      >
        <ul
          class="flex flex-col p-4 md:p-0 mt-4 font-medium border
            border-gray-100 rounded-lg md:flex-row md:space-x-8
            md:mt-0 md:border-0 dark:border-gray-700 bg-white
            dark:bg-black md:bg-transparent md:dark:bg-transparent"
        >
          <li
            v-for="(menuItem, index) in navBarMenuItems"
            :key="`menuItem-${index}`"
          >
            <NuxtLink
              v-if="menuItem"
              :to="menuItem.to"
              class="block py-2 pl-3 pr-4 rounded text-gray-900
                dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700
                dark:hover:text-white md:hover:bg-transparent
                md:dark:hover:bg-transparent dark:border-gray-700
                md:hover:text-primary-700 focus:text-primary-700
                dark:focus:text-primary-500"
              active-class="text-white bg-primary-700 md:bg-transparent
                md:text-primary-700 md:dark:text-primary-500"
              exact-active-class="text-white bg-primary-700 md:bg-transparent
                md:text-primary-700 md:dark:text-primary-500"
              aria-current="page"
              @click="navbarDropdownVisible = false"
            >
              {{ menuItem.title }}
            </NuxtLink>
          </li>
          <li
            v-if="!loggedIn"
          >
            <NuxtLink
              to="/account/signup"
              class="block py-2 pl-3 pr-4 md:px-4 rounded text-gray-900
                hover:bg-gray-100 dark:hover:bg-primary-700
                dark:hover:text-white md:hover:bg-transparent
                md:dark:hover:bg-transparent dark:border-gray-700
                md:hover:text-primary-700 dark:text-primary-900
                bg-primary"
              active-class="text-white bg-primary-700  md:bg-transparent
                md:text-primary-700 md:dark:text-primary-500"
              aria-current="page"
              @click="navbarDropdownVisible = false"
            >
              Sign Up
            </NuxtLink>
          </li>
          <li
            v-if="!loggedIn"
          >
            <NuxtLink
              to="/account/signin"
              class="block py-2 pl-3 pr-4 md:px-4 rounded text-gray-900
                hover:bg-gray-100 dark:hover:bg-primary-700
                dark:hover:text-white md:hover:bg-transparent
                md:dark:hover:bg-transparent dark:border-gray-700
                md:hover:text-primary-700 dark:text-primary-900
                bg-primary"
              active-class="text-white bg-primary-700  md:bg-transparent
                md:text-primary-700 md:dark:text-primary-500"
              aria-current="page"
              @click="navbarDropdownVisible = false"
            >
              Sign In
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import type { OnClickOutsideHandler } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useUserPreferencesStore } from '@/stores/userPreferences'
import { useUserStore } from '@/stores/user'
import { useAppConfig } from '#imports'

const appConfig = useAppConfig()
const navBarMenuItems = appConfig.ui.navbar.menu
const userMenuItems = appConfig.ui.navbar.userMenu

const userPreferencesStore = useUserPreferencesStore()
const userStore = useUserStore()
const { theme } = storeToRefs(userPreferencesStore)
const { profileImgSrc, profileName, profileEmail, loggedIn } = storeToRefs(userStore)


const autoTheme = ref('')
const colorTheme = computed(() => {
  if (theme.value === 'light') {
    return ''
  } else if (theme.value === 'dark') {
    return 'dark'
  } else if (theme.value === 'auto') {
    return autoTheme.value
  } else {
    return ''
  }
})

const userDropdownMenu = ref<HTMLElement|null>()
const navbarDropdownMenu = ref<HTMLElement|null>()
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const userDropdownVisible = ref(false)
const navbarDropdownVisible = ref(false)

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const userMenuHandler: OnClickOutsideHandler = () => {
  navbarDropdownVisible.value = false
}
const navbarHandler: OnClickOutsideHandler = () => {
  navbarDropdownVisible.value = false
}
const toggleTheme = () => {
  if (theme.value === 'light') {
    theme.value = 'dark'
  } else if (theme.value === 'dark') {
    theme.value = 'auto'
  } else if (theme.value === 'auto') {
    theme.value = 'light'
  } else {
    theme.value = 'auto'
  }
}

onMounted(() => {
  window.matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', ({ matches }) => {
      if (matches) {
        autoTheme.value = 'dark'
      } else {
        autoTheme.value = ''
      }
    })
})
</script>
