
import { defuFn } from '/vercel/path0/node_modules/.pnpm/defu@6.1.2/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "title": "Cash Studi ",
  "description": "Cash Studi ",
  "locale": "en",
  "buildDate": "2023-08-23T14:00:52.391Z"
}

import cfg0 from "/vercel/path0/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
